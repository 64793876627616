import { Controller } from '@hotwired/stimulus';

/* stimulusFetch: 'lazy' */
export default class extends Controller {
    static targets = [
        'form',
        'indicator',
        'list',
        'addActivityButton',
        'hasActivity',
    ];

    static values = {
        // value used to hook submit event of main form
        // to perform error check on hasActivity value
        stepForm: String,
    };

    connect() {
        this.nbActivities = 0;
        if (this.hasListTarget) {
            // -1 to remove header from the list
            this.nbActivities =
                this.listTarget.getElementsByTagName('tr')?.length - 1 ?? 0;

            if (this.nbActivities >= 2) {
                this.addActivityButtonTarget.classList.add('hidden');
            }
        }

        // capture the submit event of main form
        const stepForm = document.getElementById(this.stepFormValue);

        // we might be in step activity form, not edit step form
        if (stepForm) {
            stepForm.addEventListener('submit', (event) => {
                event.preventDefault();

                // get the selected radiobutton of hasActivityTarget
                const hasActivityCheckedRadio =
                    this.hasActivityTarget.querySelector(
                        'input[type="radio"]:checked',
                    );
                // if selected radiobutton is Yes and there is no activity in the list
                // we prevent the form from submitting
                if (
                    hasActivityCheckedRadio &&
                    hasActivityCheckedRadio.value === '1' &&
                    this.nbActivities === 0
                ) {
                    // append small span error message to the hasActivityTarget
                    const errorSpan = document.createElement('span');
                    errorSpan.id = 'error-message';
                    errorSpan.classList.add(
                        'text-status-negative',
                        'text-sm',
                        'mt-xs',
                    );
                    errorSpan.textContent =
                        'Vous devez ajouter au moins une activité';
                    this.hasActivityTarget.appendChild(errorSpan);
                    return;
                }

                // continue normally
                stepForm.submit();
            });
        }
    }

    showAddActivityButton(event) {
        document.getElementById('error-message')?.remove();
        if (
            event.target &&
            event.target.value === '1' &&
            this.nbActivities < 2 &&
            this.addActivityButtonTarget.classList.contains('hidden')
        ) {
            if (this.hasListTarget) this.listTarget.classList.remove('hidden');
            this.addActivityButtonTarget.classList.remove('hidden');
            return;
        }

        if (
            event.target &&
            event.target.value === '0' &&
            !this.addActivityButtonTarget.classList.contains('hidden')
        ) {
            if (this.hasListTarget) this.listTarget.classList.add('hidden');
            this.addActivityButtonTarget.classList.add('hidden');
        }
    }

    async refreshActivity() {
        if (this.hasIndicatorTarget) {
            // spinner
            this.indicatorTarget.innerHTML = `
            <span class="spinner h-m w-m animate-spin rounded-full border-2 border-solid border-current border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]">
                <span class="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]"></span>
            </span>
        `;
            if (this.hasFormTarget) {
                this.formTarget.requestSubmit();
            }
        }
    }
}
