import { Controller } from '@hotwired/stimulus';

/* stimulusFetch: 'lazy' */
export default class extends Controller {
    static targets = ['method', 'transfer'];

    connect() {
        this.updateField();
    }

    updateField() {
        const field = this[`${this.methodTarget.value}Target`];
        if (!field) {
            this.resetFields();
            return;
        }
        if (this.transferTarget.id === this.methodTarget.value) {
            this.resetFields();
            this.showField(field);
        } else {
            this.hideField(field);
        }
    }

    showField(field) {
        field.classList.remove('hidden');
    }

    hideField(field) {
        field.classList.add('hidden');
    }

    resetFields() {
        this.hideField(this.transferTarget);
    }
}
