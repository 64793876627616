import { Controller } from '@hotwired/stimulus';

/* stimulusFetch: 'lazy' */
export default class extends Controller {
    static values = {
        url: String,
    };

    copy() {
        navigator.clipboard
            .writeText(this.urlValue)
            .then(() => {
                this.element.classList.add('text-status-positive');
            })
            .catch(() => {
                this.element.classList.add('text-status-negative');
            });
    }
}
