import { Controller } from '@hotwired/stimulus';

/* stimulusFetch: 'lazy' */
export default class extends Controller {
    static targets = [];

    connect() {
        this.hideSupervisionBlocks();
        this.showSupervisionBlocks(
            this.element.querySelector(
                '[name="circuit_level[supervisionType]"]:checked',
            ).value,
        );

        this.hideIdentityBlocks();
        this.showIdentityBlocks(
            this.element.querySelector(
                '[name="circuit_level[knownSupervisorIdentity]"]:checked',
            ).value,
        );

        this.showIdentityBlocks(
            this.element.querySelector(
                '[name="circuit_level[knownMultipleSupervisorsIdentity]"]:checked',
            ).value,
        );
    }

    refreshSupervisionBlocks(event) {
        const item = event.target;
        this.hideIdentityBlocks();
        this.hideSupervisionBlocks();
        this.showSupervisionBlocks(item.value);
        this.removeDataCheckedForOneSupervisor();
    }

    hideSupervisionBlocks() {
        const blocks = this.element.querySelectorAll('[data-supervision-type]');
        blocks.forEach((element) => {
            element.classList.add('hidden');
        });
    }

    removeDataCheckedForOneSupervisor() {
        const severalSupervisorContainer = this.element.querySelector(
            '[data-supervision-type="several_supervisor"]',
        );
        if (severalSupervisorContainer) {
            // Query all elements inside the container that have data-checked="true"
            const checkedElements = severalSupervisorContainer.querySelectorAll(
                '[data-checked="true"]',
            );
            checkedElements.forEach((element) => {
                element.removeAttribute('data-checked');
            });
        }

        const oneSupervisorContainer = this.element.querySelector(
            '[data-supervision-type="one_supervisor"]',
        );
        if (oneSupervisorContainer) {
            // Query all elements inside the container that have data-checked="true"
            const checkedElements = oneSupervisorContainer.querySelectorAll(
                '[data-checked="true"]',
            );
            checkedElements.forEach((element) => {
                element.removeAttribute('data-checked');
            });
        }
    }

    showSupervisionBlocks(typeId) {
        const inputSelectedType = document.querySelectorAll(
            '[data-supervision-type]',
        );
        const matchingElements = Array.from(inputSelectedType).filter(
            (element) => {
                const values = element
                    .getAttribute('data-supervision-type')
                    .split(',');

                return values.includes(typeId);
            },
        );
        matchingElements.forEach((element) => {
            element.classList.remove('hidden');
        });
    }

    refreshIdentityBlocks(event) {
        const item = event.target;
        this.hideIdentityBlocks();
        this.showIdentityBlocks(item.value);
    }

    hideIdentityBlocks() {
        const blocks = this.element.querySelectorAll(
            '[data-supervisor-identity-type]',
        );
        blocks.forEach((element) => {
            element.classList.add('hidden');
        });
    }

    showIdentityBlocks(typeId) {
        console.log('showIdentityBlocks', typeId);
        const inputSelectedType = document.querySelectorAll(
            '[data-supervisor-identity-type]',
        );
        const matchingElements = Array.from(inputSelectedType).filter(
            (element) => {
                const values = element
                    .getAttribute('data-supervisor-identity-type')
                    .split(',');

                return values.includes(typeId);
            },
        );

        matchingElements.forEach((element) => {
            element.classList.remove('hidden');
        });
    }
}
