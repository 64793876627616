import { Controller } from '@hotwired/stimulus';

/* stimulusFetch: 'lazy' */
export default class extends Controller {
    static targets = ['sortBy', 'sortOrder', 'form'];

    connect() {
        // we get values from inputs data set by the backend
        const sortBy = document.querySelector(
            `[data-sort-by="${this.sortByTarget.value}"]`,
        );
        sortBy
            .querySelector(`[data-order="${this.sortOrderTarget.value}"]`)
            .classList.remove('hidden');
    }

    sort(event) {
        this.sortByTarget.value = event.currentTarget.dataset.sortBy;
        this.formTarget.submit();
    }

    invert() {
        this.sortOrderTarget.value =
            this.sortOrderTarget.value === 'asc' ? 'desc' : 'asc';
        this.formTarget.submit();
    }

    showArrow(event) {
        if (event.target.dataset.sortBy !== this.sortByTarget.value) {
            event.target
                .querySelector('[data-order="asc"]')
                .classList.remove('hidden');
        }
    }

    hideArrow(event) {
        if (event.target.dataset.sortBy !== this.sortByTarget.value) {
            event.target
                .querySelector('[data-order="asc"]')
                .classList.add('hidden');
        }
    }
}
