import { Controller } from '@hotwired/stimulus';

/* stimulusFetch: 'lazy' */
export default class extends Controller {
    static targets = ['periodList', 'toggleContent'];

    toggle(event) {
        const isChecked = event.target.checked;

        if (isChecked) {
            this.toggleContentTarget.classList.remove('hidden');
        } else {
            this.toggleContentTarget.classList.add('hidden');
            this.periodListTarget.innerHTML = '';
        }
    }
}
