import * as Turbo from '@hotwired/turbo';
import { registerSvelteControllerComponents } from '@symfony/ux-svelte';

// any CSS you import will output into a single css file (app.scss in this case)
import './styles/app.scss';

Turbo.session.drive = false;

// start the Stimulus application
import { startStimulusApp } from '@symfony/stimulus-bridge';

// Registers Stimulus controllers from controllers.json and in the controllers/ directory
const app = startStimulusApp();

import AccordionController from './controllers/accordion_controller.js';
import ModalController from './controllers/modal_controller.js';
import TextareaController from './controllers/textarea_controller.js';
//backpack
import AutocompleteController from './controllers/autocomplete_controller.js';
import AutocompleteTextController from './controllers/autocomplete_text_controller.js';
import CancellationFormController from './controllers/backpack/cancellation_form_controller.js';
import CircuitAccessPricingController from './controllers/backpack/circuit_access_pricing_controller.js';
import CircuitSupervisorController from './controllers/backpack/circuit_supervisor_controller';
import CopyToClipboardController from './controllers/copy_to_clipboard_controller.js';
import CreateBookingItemController from './controllers/create_booking_item_controller.js';
import CustomSelectController from './controllers/backpack/custom_select_controller.js';
import DragController from './controllers/backpack/drag_controller.js';
import DragDropController from './controllers/drag_drop_controller';
import DropzoneController from './controllers/dropzone_controller.js';
import DynamicFieldController from './controllers/dynamic_field_controller.js';
import FileUploadController from './controllers/backpack/file_upload_controller.js';
import FormBlogPostBlockController from './controllers/backpack/form-blog-post-block_controller.js';
import FormCollectionController from './controllers/form-collection_controller.js';
import FormStateSubmitController from './controllers/backpack/form_state_submit_controller.js';
import FormUploadController from './controllers/form_upload_controller.js';
import InputNumberController from './controllers/input_number_controller.js';
import MentionController from './controllers/backpack/mention_controller.js';
import MessageTemplateController from './controllers/message_template_controller.js';
import ModalConfirmController from './controllers/backpack/modal_confirm_controller.js';
import PlaceController from './controllers/place_controller';
import PlacePoiController from './controllers/place_poi_controller';
import QuillEditorController from './controllers/backpack/quill_editor_controller.js';
import RadioToggleYesnoElementController from './controllers/backpack/radio_toggle_yesno_element_controller.js';
import RefundController from './controllers/refund_controller.js';
import ResetInputsController from './controllers/reset_inputs_controller.js';
import SearchableMultiselectController from './controllers/backpack/searchable_multiselect_controller.js';
import SelectMultiplePartnerController from './controllers/select_multiple_partner_controller.js';
import SelectPaymentController from './controllers/select_payment_controller.js';
import SortBookingsController from './controllers/backpack/sort_bookings_controller.js';
import SortableLinksController from './controllers/backpack/sortable-links_controller';
import StepActivityController from './controllers/backpack/step_activity_controller.js';
import TagInputController from './controllers/backpack/tag_input_controller.js';
import ToggleController from './controllers/toggle_controller.js';
import ToggleSwitchController from './controllers/toggle_switch_controller.js';
import VpRadioController from './controllers/backpack/vp_radio_controller.js';
import SortableTableRowsController from './controllers/backpack/sortable_table_rows_controller';

// Register each controller
app.register('accordion', AccordionController);
app.register('autocomplete', AutocompleteController);
app.register('autocomplete-text', AutocompleteTextController);
app.register('backpack--cancellation-form', CancellationFormController);
app.register(
    'backpack--circuit-access-pricing',
    CircuitAccessPricingController,
);
app.register('backpack--circuit-supervisor', CircuitSupervisorController);
app.register('copy-to-clipboard', CopyToClipboardController);
app.register('create-booking-item', CreateBookingItemController);
app.register('custom-select', CustomSelectController);
app.register('backpack--drag', DragController);
app.register('drag-drop', DragDropController);
app.register('dropzone', DropzoneController);
app.register('dynamic-field', DynamicFieldController);
app.register('backpack--file-upload', FileUploadController);
app.register('backpack--form-blog-post-block', FormBlogPostBlockController);
app.register('form-collection', FormCollectionController);
app.register('backpack--form-state-submit', FormStateSubmitController);
app.register('form-upload', FormUploadController);
app.register('input-number', InputNumberController);
app.register('backpack--mention', MentionController);
app.register('message-template', MessageTemplateController);
app.register('modal', ModalController);
app.register('backpack--modal-confirm', ModalConfirmController);
app.register('backpack--quill-editor', QuillEditorController);
app.register(
    'backpack--radio-toggle-yesno-element',
    RadioToggleYesnoElementController,
);
app.register('refund', RefundController);
app.register('reset-inputs', ResetInputsController);
app.register(
    'backpack--searchable-multiselect',
    SearchableMultiselectController,
);
app.register('select-multiple-partner', SelectMultiplePartnerController);
app.register('select-payment', SelectPaymentController);
app.register('sortable-links', SortableLinksController);
app.register('backpack--sort-bookings', SortBookingsController);
app.register('backpack--step-activity', StepActivityController);
app.register('backpack--tag-input', TagInputController);
app.register('textarea', TextareaController);
app.register('toggle', ToggleController);
app.register('toggle-switch', ToggleSwitchController);
app.register('backpack--vp-radio', VpRadioController);
app.register('place', PlaceController);
app.register('place-poi', PlacePoiController);
app.register('sortable-table-rows', SortableTableRowsController);

// import custom js
import './js/partner/partner.js';
// needed for vtmn-play integration
import './css/global.css';
import './css/backpack/inbox.css';
import './css/components/input-number.scss';

registerSvelteControllerComponents(
    require.context('./svelte/backoffice/controllers', true, /\.svelte$/),
);
