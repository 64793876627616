import { Controller } from '@hotwired/stimulus';
import Sortable from 'sortablejs';

/* stimulusFetch: 'lazy' */

export default class extends Controller {
    static targets = ['container'];
    static values = {
        updateUrl: String,
        itemSelector: String,
    };

    sortable;

    connect() {
        this.initializeSortable();
    }

    initializeSortable() {
        if (!this.hasContainerTarget) return;

        this.sortable = new Sortable(this.containerTarget, {
            animation: 150,
            handle: this.itemSelectorValue || '.draggable',
            onEnd: async (event) => {
                const items = [...this.containerTarget.children];
                const positions = items.map((item, index) => ({
                    id: item.dataset.id,
                    weight: index,
                }));

                try {
                    const response = await fetch(this.updateUrlValue, {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                            'X-CSRF-TOKEN': document.querySelector(
                                'meta[name="csrf-token"]',
                            )?.content,
                        },
                        body: JSON.stringify({ positions }),
                    });

                    if (!response.ok) {
                        throw new Error('Update failed');
                    }
                } catch (error) {
                    console.error('Error updating positions:', error);
                    this.containerTarget.dispatchEvent(
                        new CustomEvent('drag-error', { bubbles: true }),
                    );
                }
            },
        });
    }
}
