import { Controller } from '@hotwired/stimulus';

/* stimulusFetch: 'lazy' */
export default class extends Controller {
    static targets = ['image', 'latitude', 'longitude', 'error'];

    static values = {
        mapUrl: String,
    };

    connect() {
        /** Dispatched by autocomplete_text_controller.js when a user selects a place */
        this.element.addEventListener(
            'place:selected',
            this.handlePlaceSelection.bind(this),
        );
    }

    async handlePlaceSelection(event) {
        const { name, placeId } = event.detail;

        try {
            const response = await fetch(
                `${this.mapUrlValue}?placeId=${placeId}&size=small`,
            );
            if (response.ok) {
                const data = await response.json();

                this.updateCoordinates(data.coordinates);
                this.displayImage(data.imageUrl);
            } else {
                throw new Error(response.status);
            }
        } catch (error) {
            console.error(error);
            this.errorTarget.classList.remove('hidden');
            this.errorTarget.textContent =
                'Une erreur est survenue pour afficher la carte';
        }
    }

    updateCoordinates(coordinates) {
        this.latitudeTarget.value = coordinates.latitude;
        this.longitudeTarget.value = coordinates.longitude;
    }

    displayImage(imageUrl) {
        const newImage = new Image();

        newImage.classList.add('w-full', 'min-h-[300px]');
        newImage.src = imageUrl;

        this.imageTarget.innerHTML = '';
        this.imageTarget.appendChild(newImage);
    }
}
