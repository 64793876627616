import { Controller } from '@hotwired/stimulus';
import 'quill/dist/quill.snow.css';

import Quill from 'quill/core';
import MarkdownToolbar from '../../js/partner/quill-markdown';

import Toolbar from 'quill/modules/toolbar';
import Snow from 'quill/themes/snow';

import Bold from 'quill/formats/bold';
import Italic from 'quill/formats/italic';
import Underline from 'quill/formats/underline';
import List from 'quill/formats/list';

Quill.register({
    'modules/toolbar': Toolbar,
    'themes/snow': Snow,
    'formats/bold': Bold,
    'formats/underline': Underline,
    'formats/italic': Italic,
    'formats/list': List,
    'modules/markdown-toolbar': MarkdownToolbar,
});

/* stimulusFetch: 'lazy' */
export default class extends Controller {
    static targets = ['editor'];
    static values = {
        isRestrictedUsage: { type: Boolean, default: false },
    };

    connect() {
        if (this.hasEditorTarget) {
            const div = document.createElement('div');
            div.innerHTML = this.editorTarget.value;
            this.editorTarget.style.display = 'none';
            this.editorTarget.parentNode.insertBefore(
                div,
                this.editorTarget.nextSibling,
            );

            // Build the default toolbar array
            let toolbarContainer = [
                [{ header: ['1', '2', '3', false] }],
                ['bold', 'italic', 'underline'],
                [{ list: 'ordered' }, { list: 'bullet' }],
                ['link'],
                ['markdown'],
                ['clean'],
            ];

            // If restricted, remove things like link & header
            if (this.isRestrictedUsageValue) {
                toolbarContainer = [
                    ['bold', 'italic', 'underline'],
                    [{ list: 'ordered' }, { list: 'bullet' }],
                    ['markdown'],
                    ['clean'],
                ];
            }

            const toolbarOptions = {
                container: toolbarContainer,
                handlers: {
                    markdown: function () {},
                },
            };

            const q = new Quill(div, {
                theme: 'snow',
                modules: {
                    toolbar: toolbarOptions,
                    'markdown-toolbar': true,
                },
            });
            let element = this.editorTarget;
            q.on('text-change', function () {
                if (q.getLength() !== 1) {
                    element.value = q.root.innerHTML;
                } else {
                    element.value = null;
                }
            });
        }
    }
}
