// tag_input_controller.js
import { Controller } from '@hotwired/stimulus';

/* stimulusFetch: 'lazy' */
export default class extends Controller {
    static targets = ['input', 'tagList', 'hiddenInput'];

    connect() {
        // Initialiser avec un tableau vide ou les tags initiaux
        this.tags = this.hiddenInputTarget.value
            ? JSON.parse(this.hiddenInputTarget.value)
            : [];

        // Créer les éléments pour les tags initiaux
        this.tags.forEach((tag) => this.createTagElement(tag));

        // Mettre à jour l'input caché
        this.updateHiddenInput();
    }

    handleKeydown(event) {
        if (event.key === 'Enter' || event.key === ',') {
            event.preventDefault();
            this.addTag();
        } else if (event.key === 'Backspace' && this.inputTarget.value === '') {
            this.removeLastTag();
        }
    }

    handleFocusOut(event) {
        if (this.inputTarget.value.trim() !== '') {
            this.addTag();
        }
    }

    addTag() {
        const value = this.inputTarget.value.trim().replace(/,/g, '');
        if (value && !this.tags.includes(value)) {
            this.tags.push(value);
            this.createTagElement(value);
            this.updateHiddenInput();
        }
        this.inputTarget.value = '';
    }

    removeTag(event) {
        const tag = event.target.closest('.tag');
        const value = tag.dataset.value;
        this.tags = this.tags.filter((t) => t !== value);
        tag.remove();
        this.updateHiddenInput();
    }

    removeLastTag() {
        if (this.tags.length > 0) {
            const lastTag = this.tagListTarget.lastElementChild;
            const value = lastTag.dataset.value;
            this.tags = this.tags.filter((t) => t !== value);
            lastTag.remove();
            this.updateHiddenInput();
        }
    }

    createTagElement(value) {
        const tag = document.createElement('div');
        tag.className =
            'tag inline-flex items-center bg-blue-brand text-white px-xs py-2xs rounded';
        tag.dataset.value = value;
        tag.innerHTML = `
          ${value}
          <button type="button" class="ml-2xs text-white bg-blue-brand hover:text-blue-800" data-action="click->backpack--tag-input#removeTag">
             &times;
          </button>
        `;
        this.tagListTarget.appendChild(tag);
    }

    updateHiddenInput() {
        this.hiddenInputTarget.value = JSON.stringify(this.tags);
    }
}
