import { Controller } from '@hotwired/stimulus';

/* stimulusFetch: 'lazy' */
export default class extends Controller {
    static targets = [
        'catalogItem',
        'newItemName',
        'newItemPrice',
        'newItemDescription',
        'newItemType',
        'newItemNonCommissionableAmount',
    ];

    static values = {
        index: Number,
        prototype: String,
        prototypeName: { type: String, default: 'name' },
    };

    setNewItemValues(event) {
        const catalogItemOption =
            this.catalogItemTarget.selectedOptions[0].dataset;
        this.newItemNameTarget.value = catalogItemOption.name;
        this.newItemPriceTarget.value = catalogItemOption.price;
        this.newItemDescriptionTarget.value = catalogItemOption.descr;
        this.newItemTypeTarget.value = catalogItemOption.type;
        this.newItemNonCommissionableAmountTarget.value =
            catalogItemOption.nonCommissionableAmount;
    }
}
