// assets/controllers/backpack/custom_select_controller.js
import { Controller } from '@hotwired/stimulus';

/* stimulusFetch: 'lazy' */
export default class extends Controller {
    static targets = ['display', 'dropdown', 'input', 'selected', 'search'];

    connect() {
        document.addEventListener('click', this.clickOutside);
    }

    disconnect() {
        document.removeEventListener('click', this.clickOutside);
    }

    toggle(event) {
        event.stopPropagation();
        const isHidden = this.dropdownTarget.classList.contains('hidden');
        this.dropdownTarget.classList.toggle('hidden');
        // When opening, clear any previous search and focus on the search input
        if (isHidden && this.hasSearchTarget) {
            this.searchTarget.value = '';
            this.filterOptions({ target: this.searchTarget });
            this.searchTarget.focus();
        }
    }

    select(event) {
        event.preventDefault();
        const selectedValue = event.currentTarget.getAttribute('data-value');
        const selectedText = event.currentTarget.textContent.trim();
        this.selectedTarget.textContent = selectedText;
        this.inputTarget.value = selectedValue;
        // Close the dropdown after selection
        this.dropdownTarget.classList.add('hidden');
    }

    filterOptions(event) {
        // Normalize search input by removing diacritics
        const searchValue = event.target.value.toLowerCase();
        const normalizedSearchValue = searchValue
            .normalize('NFD')
            .replace(/[\u0300-\u036f]/g, '');
        const liElements = this.dropdownTarget.querySelectorAll('li');
        liElements.forEach((li) => {
            const text = li.textContent.toLowerCase();
            const normalizedText = text
                .normalize('NFD')
                .replace(/[\u0300-\u036f]/g, '');
            li.style.display = normalizedText.includes(normalizedSearchValue)
                ? ''
                : 'none';
        });
    }

    clickOutside = (event) => {
        if (
            !this.element.contains(event.target) &&
            !this.dropdownTarget.classList.contains('hidden')
        ) {
            this.dropdownTarget.classList.add('hidden');
        }
    };
}
