import { Controller } from '@hotwired/stimulus';
import Sortable from 'sortablejs';

export default class extends Controller {
    static targets = ['tbody', 'item', 'position'];

    connect() {
        if (!this.hasTbodyTarget) {
            console.error('Sortable controller: tbody target not found.');
            return;
        }

        this.sortable = Sortable.create(this.tbodyTarget, {
            animation: 150,
            onSort: this.updatePositions.bind(this),
        });

        this.updatePositions();
    }

    updatePositions() {
        // Iterate over the item targets and update the position data
        this.itemTargets.forEach((element, index) => {
            element.dataset.position = index + 1;
            // Instead of using querySelector, update the corresponding position target.
            if (this.positionTargets[index]) {
                this.positionTargets[index].textContent = index + 1;

                const itemId = element.dataset.id;
                const inputItemId = document.querySelector(
                    `[data-input-item-id="${itemId}"]`,
                );

                if (inputItemId) {
                    inputItemId.value = index;
                }
            }
        });
    }
}
