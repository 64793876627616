const charLimitInputs = document.querySelectorAll('[data-char-limit]');

charLimitInputs?.forEach((input) => {
    const charLimit = parseInt(input.dataset.charLimit);
    const charLimitDisplay = document.getElementById(`char-limit[${input.id}]`);
    if (charLimitDisplay)
        charLimitDisplay.innerHTML = input.value ? input.value.length : 0;
    input.addEventListener('input', () => {
        if (input.value.length > charLimit) {
            input.value = input.value.substring(0, charLimit);
            charLimitDisplay.innerHTML = charLimit;
            charLimitDisplay.classList.add('text-status-negative');
        } else {
            charLimitDisplay.innerHTML = input.value.length;
            charLimitDisplay.classList.remove('text-status-negative');
        }
    });
});
