import { Controller } from '@hotwired/stimulus';

/* stimulusFetch: 'lazy' */
export default class extends Controller {
    static targets = ['overlay', 'dialog'];
    static outlets = ['modal'];
    static values = {
        open: {
            type: Boolean,
            default: false,
        },
        drawer: { type: Boolean, default: false },
    };

    initialize() {
        this.forceClose = this.forceClose.bind(this);
    }

    connect() {
        if (this.openValue) {
            this.open();
        }

        document.addEventListener('turbo:before-render', this.forceClose);

        // Create a bound handler for the escape key
        this.handleKeyDown = this.handleKeyDown.bind(this);
        document.addEventListener('keydown', this.handleKeyDown);
    }

    disconnect() {
        document.removeEventListener('turbo:before-render', this.forceClose);
        document.removeEventListener('keydown', this.handleKeyDown);
    }

    handleKeyDown(event) {
        if (event.key === 'Escape') {
            // Find all open dialogs
            const openDialogs = document.querySelectorAll('dialog[open]');
            if (openDialogs.length > 0) {
                // Prevent the default Escape behavior for dialogs
                event.preventDefault();
                // Close the dialog with animation
                this.close();
            }
        }
    }

    open() {
        const dialog = this.modalOutletElement.querySelector('dialog');
        if (!dialog) return;

        if (this.drawerValue) {
            dialog.classList.add('drawer-right');
        }
        // Add click handler for backdrop click
        this.backdropClickHandler = this.backdropClose.bind(this);
        dialog.addEventListener('click', this.backdropClickHandler);

        dialog.showModal();

        const event = new CustomEvent('modal-open', this.modalOutletElement);
        window.dispatchEvent(event);
    }

    close() {
        const dialog = this.modalOutletElement.querySelector('dialog');
        if (!dialog) return;

        dialog.setAttribute('closing', '');
        // Get all animations after setting the closing attribute
        const animations = dialog.getAnimations();

        if (animations.length === 0) {
            // If no animations, close immediately
            this.finalizeClose(dialog);
        } else {
            Promise.all(animations.map((animation) => animation.finished)).then(
                () => {
                    this.finalizeClose(dialog);
                },
            );
        }

        const event = new CustomEvent('modal-close', this.modalOutletElement);
        window.dispatchEvent(event);
    }

    // Helper method to finalize closing
    finalizeClose(dialog) {
        // Remove click handler when closing
        dialog.removeEventListener('click', this.backdropClickHandler);

        dialog.removeAttribute('closing');
        if (this.drawerValue) {
            dialog.classList.remove('drawer-right');
        }
        dialog.close();
    }

    backdropClose(event) {
        const dialog = this.modalOutletElement.querySelector('dialog');
        if (!dialog) return;

        if (event.target === dialog && dialog.hasAttribute('open')) {
            this.close();
        }
    }

    forceClose() {
        this.modalOutletElement.querySelector('dialog')?.close();
    }
}
