import { Controller } from '@hotwired/stimulus';

/* stimulusFetch: 'lazy' */
export default class extends Controller {
    static targets = [''];

    connect() {
        this.hideInputs();
        this.showInputs(
            this.element.querySelector('[name="blog_post_block[type]"]').value,
        );
    }

    refreshInputs(event) {
        const item = event.target;
        this.hideInputs();
        this.showInputs(item.value);
    }

    hideInputs() {
        const inputs = this.element.querySelectorAll('[data-block-type]');
        inputs.forEach((element) => {
            element.parentElement.parentElement.classList.add('hidden');
            element.setAttribute('required', false);
            element.disabled = true;
        });
    }

    showInputs(typeId) {
        const inputSelectedType = this.element.querySelectorAll(
            '[data-block-type="' + typeId + '"]',
        );
        inputSelectedType.forEach((element) => {
            element.parentElement.parentElement.classList.remove('hidden');
            element.setAttribute('required', true);
            element.disabled = false;
        });
    }
}
