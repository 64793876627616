import { Controller } from '@hotwired/stimulus';

/* stimulusFetch: 'lazy' */
export default class extends Controller {
    static targets = [
        'image',
        'latitude',
        'longitude',
        'itineraryType',
        'zoomLevel',
        'zoomLevelInput',
        'address',
        'gpx',
        'itineraryButton',
        'geoJson',
        'error',
        'poi',
        'poiCollection',
    ];

    static values = {
        getMapUrl: String,
        postMapUrl: String,
        gpxUrl: String,
        poiUrl: String,
        poiCollection: Array,
    };

    connect() {
        /** Dispatched by autocomplete_text_controller.js when a user selects a place */
        this.element.addEventListener(
            'place:selected',
            this.handlePlaceSelection.bind(this),
        );

        if (this.hasZoomLevelTarget) {
            const zoomLevelInputNumber = this.zoomLevelTarget.querySelector(
                '#zoomLevelInputNumber',
            );
            zoomLevelInputNumber.addEventListener(
                'input',
                this.updateZoomLevel.bind(this),
            );
        }

        if (this.hasImageTarget) {
            if (this.hasPoiTarget) {
                this.initializePoiCollection();
            }

            const existingImg = this.imageTarget.querySelector('img');

            if (
                existingImg &&
                this.hasItineraryTypeTarget &&
                existingImg.dataset.default === 'false'
            ) {
                const button = this.getButtonByValue(
                    this.itineraryTypeTarget.value,
                );
                this.activateButton(button);

                if (this.itineraryTypeTarget.value === 'itinerant') {
                    this.displayGpxInput();
                    this.displayGpxName(existingImg.dataset.gpxName);
                    if (
                        this.hasPoiCollectionValue &&
                        this.poiCollectionValue.length > 0
                    ) {
                        this.displayZoomLevelInput();
                    }
                } else {
                    this.displayZoomLevelInput();
                    this.displayAddressInput();
                }
                this.displayMapLayer();
            } else {
                this.imageTarget.classList.add('hidden');
            }
        }

        if (this.hasPoiTarget) {
            this.initializePoiCollection();
        }
    }

    async handleGpxUpload(event) {
        const file = event.target.files[0];
        if (!file) {
            return;
        }

        const formData = new FormData();
        formData.append('gpx', file);

        try {
            const response = await fetch(this.gpxUrlValue, {
                method: 'POST',
                body: formData,
            });

            if (response.ok) {
                const data = await response.json();

                this.displayImage(data.url);
                this.updateGeoJson(data.geoJson);
                this.displayGpxName(data.filename);
            } else {
                if (response.status === 400) {
                    const data = await response.json();
                    this.errorTarget.classList.remove('hidden');
                    this.errorTarget.textContent = data.error;
                    return;
                }
                throw new Error(response.status);
            }
        } catch (error) {
            this.errorTarget.classList.remove('hidden');
            this.errorTarget.textContent =
                'Une erreur est survenue pour afficher la carte';
            console.error(error);
        }
    }

    async handlePlaceSelection(event) {
        const { name, placeId } = event.detail;

        try {
            const response = await fetch(
                `${this.getMapUrlValue}?placeId=${placeId}`,
            );
            if (response.ok) {
                const data = await response.json();

                this.updateCoordinates(data.coordinates);
                this.updateZoomLevelValue();
                this.displayImage(data.imageUrl);
            } else {
                throw new Error(response.status);
            }
        } catch (error) {
            this.errorTarget.classList.remove('hidden');
            this.errorTarget.textContent =
                'Une erreur est survenue pour afficher la carte';
        }
    }

    async handleZoomChange(value) {
        if (isNaN(value)) {
            return;
        }

        try {
            const formattedPoi = JSON.stringify(this.poiCollectionValue);
            const baseData = {
                zoomLevel: value,
                latitude: this.latitudeTarget.value,
                longitude: this.longitudeTarget.value,
                poi: formattedPoi,
            };

            let response;
            if (this.geoJsonTarget.value) {
                response = await fetch(this.postMapUrlValue, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        ...baseData,
                        geoJson: this.geoJsonTarget.value,
                    }),
                });
            } else {
                const queryParams = new URLSearchParams(baseData);
                response = await fetch(
                    `${this.getMapUrlValue}?${queryParams.toString()}`,
                );
            }

            if (response.ok) {
                const data = await response.json();
                this.displayImage(data.imageUrl);
            } else {
                throw new Error(response.status);
            }
        } catch (error) {
            this.errorTarget.classList.remove('hidden');
            this.errorTarget.textContent =
                'Une erreur est survenue pour afficher la carte';
        }
    }

    initializePoiCollection() {
        this.poiCollectionValue = this.poiCollectionTargets.map((poi) => {
            let [lon, lat] = poi.innerHTML
                .split(',')
                .map((coord) => parseFloat(coord.trim()));
            return {
                longitude: lon,
                latitude: lat,
            };
        });
    }

    updateZoomLevel(event) {
        this.zoomLevelInputTarget.value = event.target.value;
        this.handleZoomChange(event.target.value);
    }

    updateZoomLevelValue() {
        const zoomInput = this.zoomLevelTarget.querySelector('input');
        zoomInput.value = '14'; // Default value from Mapbox
    }

    updateCoordinates(coordinates) {
        this.latitudeTarget.value = coordinates.latitude;
        this.longitudeTarget.value = coordinates.longitude;
    }

    updateGeoJson(data) {
        this.geoJsonTarget.value = JSON.stringify(data);
    }

    getButtonByValue(value) {
        return this.itineraryButtonTargets.find(
            (button) => button.value === value,
        );
    }

    handleItineraryTypeChange(event) {
        this.resetButtonClass();
        this.activateButton(event.target);

        const itineraryType = event.target.value;
        this.itineraryTypeTarget.value = itineraryType;

        this.hideInputs();
        if (itineraryType === 'fixed' || itineraryType === 'base_camp') {
            this.displayAddressInput();
            this.displayZoomLevelInput();
            this.displayPoiList();
        } else if (itineraryType === 'itinerant') {
            this.displayGpxInput();
        }
        this.displayMapLayer();
    }

    resetButtonClass() {
        this.itineraryButtonTargets.forEach((button) => {
            button.classList.remove('vp-button');
            button.classList.add('vp-button', 'vp-button--secondary');
        });
    }

    activateButton(element) {
        element.classList.remove('vp-button--secondary');
    }

    displayImage(imageUrl) {
        const newImage = new Image();

        newImage.classList.add('w-full', 'min-h-[300px]');
        newImage.src = imageUrl;

        const title = this.imageTarget.querySelector('h2');
        const infobox = this.imageTarget.querySelector('div');

        this.imageTarget.innerHTML = '';

        this.imageTarget.appendChild(title);
        if (infobox) {
            this.imageTarget.appendChild(infobox);
        }
        this.imageTarget.appendChild(newImage);
    }

    hideInputs() {
        this.imageTarget.classList.add('hidden');
        this.addressTarget.classList.add('hidden');
        this.zoomLevelTarget.classList.add('hidden');
        this.gpxTarget.classList.add('hidden');
        if (this.hasPoiTarget) {
            this.poiTarget.classList.add('hidden');
        }
    }

    displayPoiList() {
        if (this.hasPoiTarget) {
            this.poiTarget.classList.remove('hidden');
        }
    }

    displayMapLayer() {
        this.imageTarget.classList.remove('hidden');
    }

    displayAddressInput() {
        this.addressTarget.classList.remove('hidden');
    }

    displayZoomLevelInput() {
        this.zoomLevelTarget.classList.remove('hidden');
    }

    displayGpxInput() {
        this.gpxTarget.classList.remove('hidden');
    }

    displayGpxName(name) {
        const span = this.gpxTarget.querySelector('#gpxName');
        span.textContent = name;
    }

    openFile() {
        const input = this.gpxTarget.querySelector('input');
        input.click();
    }
}
